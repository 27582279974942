<template>
  <section>
    <div class="content-header">
      <h2>Greatest strengths</h2>
    </div>
    <div class="content-wrapper">
      <p>Looking for ways to broach the subject of resilience with your students? This trio of resources will help you guide your students as you explore character strengths together. </p>
      <ul>
        <li class="pb-3">Encourage students to take the <a
            href="https://www.viacharacter.org/survey/account/register"
            target="_blank"
          >VIA Character Strengths Survey</a>. This free self-assessment takes less than 15 minutes and provides a wealth of information to help people understand their best qualities. Once the survey is complete, students receive a personalized, in-depth analysis of their results, including actionable tips to apply their strengths to find greater well-being and increase resilience.</li>
        <li>Show your students this video on character strengths. Over the course of 18 minutes, Dr. Brecher presents a deeper exploration of character strengths and proposes some activities to get students thinking about their own personal strengths.
          <div class="video-player pt-3">
            <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/iSDTG6GPCi0?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
            <!-- <vue-plyr>
            <div class="plyr__video-embed">
                <iframe
                  src="https://www.youtube.com/embed/iSDTG6GPCi0?rel=0"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                ></iframe>
              </div>
          </vue-plyr> -->
            <!-- <vue-plyr>
              <video
                controls
                crossorigin
                playsinline
              >
                <source
                  size="720"
                  src="https://staging.gevc.ca/centennial/tic/videos/personal/discuss/2a.mp4"
                  type="video/mp4"
                />
                <track
                  label="English"
                  kind="subtitles"
                  srclang="en"
                  src="https://staging.gevc.ca/centennial/tic/cc/personal/discuss/02a.en.vtt"
                  default
                >
              </video>
            </vue-plyr> -->
            <div class="accordion">
              <accordion
                headerText="Transcript"
                accordionID="transcript01"
              >
                <p>Hello, my name is Diana Brecher and I'm here to talk with you about our strengths and how these character strengths can help us in all of our goals, be it in the classroom, in the workplace or in our personal lives. </p>
                <p>Based on research that was done by Chris Peterson and Martin Seligman back in 2004, they identified 24 character strengths that are common across cultures, across different countries in the world that we all tend to share these 24 strengths. And so you can see in this slide that the strengths are identified in the circle that surrounds the centre and in the centre are six virtues that are associated each with a different cluster of character strengths. </p>
                <p>So the virtue of courage is associated with bravery, persistence, integrity, vitality, for example. Whereas the virtue of justice is associated with leadership, fairness, and citizenship and so forth. </p>
                <p>So in each case there's a different cluster of character strengths that are associated with a particular virtue or value. And all of these lead to a life of pleasure and engagement. </p>
                <p>So another way of thinking about it is that our strengths are our values that we put into action. We lead from them and manifest them. So it is a way of thinking about your character strengths. </p>
                <p>I have a fairly extensive list of values and it goes over for three slides. And what I'm going to ask you to do is to read through the list and, when one of these values seems to really resonate for you, jumps out and you go, “Yeah, that's really important to me.” Write it down. So maybe it's competence or competition, creativity, or discipline, or freedom, or faith, or family, or fairness or discovery. Select what seems to really jump out at you and I'll give you a moment to do that. And then we'll go onto the next slide because this is quite an extensive list. </p>
                <p>So same thing, scan the list and choose what really resonates for you.</p>
                <p>What's likely to happen is the list that you've just created from these values should be in some way associated with the results of the character strengths survey that you took before today, where that survey helps you identify the values that you've put into action, which turn into your character strengths.</p>
                <p>And what I'm hoping is that you'll find the value with that were on these two slides helps you to see the connection between the values that you have and the strengths that you can tap into.</p>
                <p>So there's a concept called a signature strength. A signature strength is usually your top five or six; what best characterizes you. And what you endorse is what you use most often in the survey that you took. </p>
                <p>So our signature character strengths are stable across time. So over a period of years, you'll find that the same strengths come easily to you over and over again. It's stable across contexts, be it at work, or at play, or in your family life. And they come really naturally with ease. And most importantly, we're really proud of our strengths. If you look at your top strengths, I bet you would feel pretty comfortable saying to yourself, this defines me. It also, these strengths give us information about who we are. And so there may be some surprises that came out of the survey that make you wonder, “I never thought that I really was that curious or that connected to compassion.” They reflect our positive personality traits. They're the things we're most proud of. And they're also part of our core identity.</p>
                <p>And that tends to lead to being a wellspring for good and a full life. So there are virtually no downsides to first identifying your strengths and then intentionally using them and incorporating them into your daily life. </p>
                <p>One of the ways to think about the 24 character strengths is to look at the top six. These are easily expressed, they're stable, they're engaging and interesting to you. </p>
                <p>Then there's your middle strengths, where you don't want to use them as often but you look to access them if you need to. So let's say one of your middle strengths is courage and in your day-to-day life, you don't necessarily need a lot of courage, but when you face a really difficult, challenging, scary time, you may want to kind of dig deep and find that courage within yourself and almost pull it up through the list so that you can use it more often. </p>
                <p>And then our lower strengths are usually less interesting to us. There's research to suggest that trying to develop the lower strengths is not really a great use of time. A much better use of time is to work with your signature strengths and possibly to cultivate your middle strengths. </p>
                <p>There's some interesting research that was done by Gemma Williams and this is part of a quote: “Employees who report using four or more of their signature strengths on a regular basis at work, not only experience more positive work experience but are significantly more than likely to consider their work a ‘calling’.” So a way to define the term a “calling” is that it's something that you do because of the love of it, because you're invested in it. You're interested in, you get so much satisfaction from the work that it gives your life a sense of purpose and meaning. And the financial, you know, your salary, doesn't actually, that's not your motivation for doing the work. The work itself is your motivation for doing the work. That's when you experience work as a calling. </p>
                <p>And, in contrast, this is based on a Gallup poll back in 2018, when they found that 70% of the U.S. workforce are not fully engaged. So they found that in 2018, about 34% of the employees that they surveyed said that they felt really engaged, they worked with passion and had a profound connection to that company. When you look at the group number two, 53% are not engaged. They’re kind of checked out, they're just doing the job they have to do, but with very little energy or passion. </p>
                <p>And there's another group that's actively disengaged. They actually sabotage often and they undermine their coworkers. And then if you look at the average over the past 20 years, you'll see that the numbers are pretty consistent. Give or take a few numbers across time. The same, 30 to 34%, more or less of employees are engaged and the rest are not. And so one of the ways to work around that is to really tap into our strengths and use them to connect to the work we're doing, whether we're initially really interested or not.</p>
                <p>So let's talk about exploring your character strengths and how can you do that? So you've done the survey, which has given you a rank order of the 24 character strengths. And today we're going to be focusing mostly on the top six. And so one way to think about it is to ask these questions. How can I bring out the best? Who do I want to be? How can I increase my competence? And so these explorations can help you find some of those answers. So I want you to think about a time when you were at your best, with which character strengths did you lead, and how about when you faced a significant challenge, which character strengths helped you to thrive?</p>
                <p>And the answers to those are useful not only for yourself on a day-to-day basis, but they might be the kind of question that's asked of you in a job interview or for an opportunity for some extracurricular activities. So knowing what your strengths are and how you use those, it'd be very helpful even when you're trying to present yourself and market yourself in a particular way. </p>
                <p>This is a quick snapshot of the 24 strengths. They're not in any particular order. There's no objectively better strengths to have or not have and each of us has our constellation of that rank order of 24 strengths. </p>
                <p>So are you leading with zest and curiosity? or bravery and honesty? or love of learning and forgiveness? or prudence with spirituality? In any of those scenarios and all the other ones, they're all equally valid and useful and most useful if you know and can identify the strengths. So kind of in summary, a character strength is understood as a profile of your strengths. It's not just one, it's a constellation of them. And they are shaped by the context you’re in. And so the same strength can manifest a little differently in a school or work setting than in a personal or family setting. And oftentimes, our strengths are expressed in a combination of things. Like curiosity and creativity or zest and love of learning, for example. And although they tend to be stable across time, they can and do change in response to really important life events. So you could do this character strengths survey in another two or three years. And you may find that there's been some movement among them. Something that was a middle strength is moved up to the top, and that becomes a really interesting question to explore how and why. </p>
                <p>An optimal strength, the use of these optimal strengths usually occurs according to the golden mean. I want to talk briefly about that. It's the notion of overused or underused. So the strength is only a strength in the sweet spot. It's not really a strength if you're overusing it or underusing it; I'll explain why.</p>
                <p>So can you think of a time when you overused or underused a strength and what were the consequences of that in each context? So if we think about the goal, the golden mean it's the optimal use; it's that balance between excess and efficiency. So here's some examples. So for bravery, you could lack bravery to stand up for someone who's being teased, for example, or disrespected. You could lack perseverance when you give up on a work project. You could lack compassion when you don't forgive someone who's wronged you. You can lack curiosity by not asking enough questions to support someone who's struggling. So underuse is simply, to varying degrees, the absence of, or much less of what was perhaps once it's strength. </p>
                <p>And overuse is when you use it too often or too intensely. So for example, curiosity can be experienced as being really nosy when questions are unwelcome. So too much curiosity can actually interfere with some social relationships. Perseverance can be experienced as stubborn and unrelenting, refusing to give up even when it's clearly impossible, or a toxic relationship, for example.</p>
                <p>So sometimes perseverance really gets you through really, really hard times and other times perseverance can keep you in a situation that's counterproductive. And so really turning into whether or not you're overusing or underusing can be a helpful guide. </p>
                <p>Okay. So, what can you try? You can discover which of your strengths are essential to you and your identity by… they basically come naturally. You could ask for feedback from the people around you. And so, this is a link, the 360 strengths feedback, which has a PDF form, and there are questions that you can ask the people closest to you to fill out, giving you feedback on what they perceive to be your greatest strengths. This is a very useful, tool to use, for example. You could say to yourself, I'm going to practice leading with one of my top six strengths today. And just notice what happens. You can be more intentional about avoiding the over or under use of any of your strengths and to kind of think to yourself: mmh, maybe I'm overusing this thing right now. And that's why things feel so depleting. And I'm so exhausted. And then you can leverage your strengths and I'm going to be showing you the alignment chart.</p>
                <p>So the first exercise I want to take you through is to just imagine not being allowed to use your top strength for a month. And so I'm sure when you imagine that question, a word came to mind or a feeling came, what was it? Some people in different sessions when I've done this workshop have said, oh, the first thing that came to mind was I would feel despair or scared or exhausted.</p>
                <p>So we rely on our strengths so much that to even imagine not being able to tap into them could be quite upsetting to us. I've also had students say to me, oh, I just use a different strength. Right? So really practical. They were not at all upset, but that's something to think about. How do we value something sometimes in its absence.</p>
                <p>So this is what we call strength spotting in tough times. So think about a situation where you went through a really tough time and yet you persevered and you successfully overcame that challenge. Which of your strengths helped you to overcome this challenge? What can you rely on when things are really tough? Knowing that is part of your toolbox and you can just bring them out more intentionally next time you find yourself in a tough situation.</p>
                <p>So really it's about a question of how can you leverage this strength? In all kinds of settings: at home, at school at work, a challenging situation, mundane tasks in a relationship, or even when your health is at risk. So here's one way to think about how to do that. </p>
                <p>So I'd like to talk about strengths alignment, which is really how can we incorporate and lead from our particular strengths when we are engaged in tasks that we have to complete.</p>
                <p>And so rather than feeling like we're leading out of weakness, can we lead from our strengths? So take a piece of paper and just draw this chart, the top five strengths and the top tasks that you have to engage in right now. These can be school, work or a combination of two. Next, fill in using your character strengths survey, the top five strengths that were identified in your results. So in this case, it's curiosity, humor, courage, perseverance, zest. Then you fill out the top task that you have to engage in right now. And I'll give you an example in a moment. And this is about, are you using all of your strengths or one or only some of your strengths? So in this case, the top tasks are groupwork, lab reports, completing your readings on time, being prepared for lectures, and forming a study group. And in this example, you're just using curiosity for all of these tasks. And the problem with that, even though intuitively might make a lot of sense, is that you aren't in fact, spreading things out and using different strengths in different contexts that make things richer and easier for you. Or maybe you're using perseverance and zest for these five tasks, but not tapping into curiosity at all.</p>
                <p>Or maybe you're using each of your strengths for a different task. So maybe you are using curiosity for groupwork and humor to form a study group and courage in your being prepared for lectures and so forth. So, one of the things that I would recommend is that if you can spread out your strengths across all the things you have to do, what you'll begin to notice is it's more fun, it's more engaging and uses… you need less effort to get the work done because you're already leading from your strengths.</p>
              </accordion>
            </div>
            <p><a :href="`${publicPath}docs/personal/Character-strengths.pdf`" target="_blank">Open transcript in a new tab</a></p>
          </div>
        </li>
        <li class="pt-3">Watch Dr. Brecher’s debrief video to learn about how to use the video and how to facilitate a discussion with your students after watching the character strengths video.
          <div class="video-player pt-3">
            <div class="ratio ratio-16x9">
  <iframe
    src="https://www.youtube.com/embed/5HDHetZePOE?rel=0&cc_load_policy=1"
    title="YouTube video"
    allowfullscreen
  ></iframe>
</div>
            <!-- <vue-plyr>
            <div class="plyr__video-embed">
                <iframe
                  src="https://www.youtube.com/embed/5HDHetZePOE?rel=0"
                  allowfullscreen
                  allowtransparency
                  allow="autoplay"
                ></iframe>
              </div>
          </vue-plyr> -->
            <!-- <vue-plyr>
              <video
                controls
                crossorigin
                playsinline
              >
                <source
                  size="720"
                  src="https://staging.gevc.ca/centennial/tic/videos/personal/discuss/2b.mp4"
                  type="video/mp4"
                />
                <track
                  label="English"
                  kind="subtitles"
                  srclang="en"
                  src="https://staging.gevc.ca/centennial/tic/cc/personal/discuss/02b.en.vtt"
                  default
                >
              </video>
            </vue-plyr> -->
            <div class="accordion">
              <accordion
                headerText="Transcript"
                accordionID="transcript02"
              >
                <p>Hello, my name is Diana Brecher, and I'm here to do a bit of a debrief on using character strengths in the classroom. So you've likely shown your students the video that I created on character strengths, which is an introduction to what are strengths in the first place and how can we use them and how can we be more intentional in how we use them.</p>
                <p>And so what I'd like to do now is to really pose the question, what would it take to lead from your strengths and to help your students identify and cultivate their strengths in the class? So, what we had requested was that the students take the VIA character strength survey before they watch the video in class.</p>
                <p>So perhaps to sign up the week before, and I have the link in this video in another slide <a href="https://www.viacharacter.org/survey/account/register" target="_blank">(https://www.viacharacter.org/survey/account/register)</a> and you yourself as the instructor and even your TAs might be interested in taking the character strengths survey yourselves, just so that everyone is literally on the same page. And so we start with that first self-knowledge, and then the video that you show the students is about helping them understand and unpack their understanding of their specific profile of the 24 character strengths and their top strengths in particular. </p>
                <p>And so in this debrief, we're going to talk about three key things. How can this video be used in the classroom? How can the concept of strengths be applied? And, how can students learn to operationalize what these strengths mean to them? So let's start with how the video can be used in the classroom. And perhaps even when to use it. So you could decide to do it really pretty early on in the term where you want to convey to the students how important you think their strengths are in the first place, both in terms of getting through all of the work that's part of this course, but also how they can work together when they're collaborating through a group project or working in pairs on a particular assignment. And so that knowing your strengths and knowing the strengths of the people you're working with can help you all be much more intentional with who does what and why.</p>
                <p>So as someone who is you know, incredibly curious and has a love of learning, you might be asking that person to do a particular task in a group project that would be really different than someone who’s very prudent, and prudence is their top strength. And so then they may be someone who is really going to chase after all the details and be really sure that the sequence of everything works really well for example.</p>
                <p>So this could be done early on in a semester, or it could be just before people break into groups to do group projects. And it has many benefits. So if everyone knows what their strengths are in a group, for example, it kind of deepens the equity and inclusion principles that we would really like to see in a classroom and in a group, because it helps us to understand the different strengths that people bring to the same process.</p>
                <p>You can understand yourself better and your peers by simply knowing what their top strengths are and how to tap into them. It also increases the kind of sense of students being much more efficient in how they study by understanding what their strengths are. They can better know how to navigate the workload or the expectations. And it sets the tone early on for everyone. All the students, if an instructor is saying to their students, we really think your strengths are important and we want you to use them. And there may in fact be a way that as the instructor, you can talk about how you used your strengths as a student or as how you use them in your work today. And in a sense endorsing, but also role modeling how these strengths can be applied. And if you have teaching assistants in the class and they've gone through this process as well, they can be brought in as kind of peers, but a little bit ahead of the students in the class who can understand how when the teaching assistants reflect back on their early years at university, how that may have in fact made a difference for them to understand what their strengths are and how to be more intentional using them.</p>
                <p>And then kind of part and parcel with that is learning to operationalize what their strengths mean to them. You can even invite students to have a conversation perhaps in small groups or even in the large classroom about how they could be applying different strengths to different tasks. So the strengths alignment exercise that we finished off the video with could be kind of a [unclear] where a discussion that really looks at how these strengths can make a difference as in their journey. I also wanted to mention there are several resources that we're happy to share with you for you to understand more about character strengths, but also these can be resources that can be provided to your students and they're in the resource list that we've provided. </p>
                <p>So this is the values and action character strengths survey. It is a link that will take you directly to the survey. You have to log in and it is free. It takes between 15 to 20 minutes to complete. And at the end the students will get a report of their, the rank order of their 24 strengths. I would recommend asking your students to have done this survey in advance of watching the video and to bring that list of top strengths to the class when they are watching the video so that they can easily interact, particularly with the strengths alignment exercise.</p>
                <p>If you have any questions about character strengths, these are my coordinates. You're more than welcome to email me and my colleague and partner in this endeavor is Fenella. And you can contact her directly with that email address. Thank you very much.</p>
                <p><a href="https://www.viacharacter.org/survey/account/register" target="_blank">https://www.viacharacter.org/survey/account/register</a></p>
                <p>(Diana Brecher: <a
                    href="mailto:dbrecher@ryerson.ca"
                    target="_blank"
                  >dbrecher@ryerson.ca</a>). (Fenella Amarasinghe <a
                    href="mailto:famarasinghe@ryerson.ca"
                    target="_blank"
                  >famarasinghe@ryerson.ca)</a>.</p>
                <p></p>
                <p></p>

              </accordion>
            </div>
            <p><a
                :href="`${publicPath}docs/personal/Character-strengths-debrief.pdf`"
                target="_blank"
              >Open transcript in a new tab</a></p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
